import App from 'next/app'
import Router, { useRouter } from 'next/router'
import { useEffect } from 'react'
import NProgress from 'nprogress' //nprogress module

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const isProduction = process.env.NODE_ENV === 'production'
import 'styles/global.css'
import 'styles/map.css'
import 'styles/nprogress.css' //styles of nprogress

import * as gtag from 'lib/gtag'
import { SiteLayout } from 'components/SiteLayout'
import useRouterScroll from 'lib/hooks/useRouterScroll'

function GNApp({ Component, pageProps, err }: any) {
  const router = useRouter()
  useRouterScroll()
  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      /* invoke analytics function only for production */
      if (isProduction) gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <SiteLayout>
      <Component {...pageProps} err={err} />
    </SiteLayout>
  )
}

export default GNApp
